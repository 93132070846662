import { make } from 'vuex-pathify';

const daqSiteCode = process.env.VUE_APP_DAQ_SITE_CODE;
const epwcSiteCode = process.env.VUE_APP_EPWC_SITE_CODE;

const state = {
  site: null,
  loading: false
};

const getters = make.getters(state);

const mutations = make.mutations(state);

const actions = {
  async setSite({ commit }) {
    commit('SET_LOADING', true);
    const sites = await this.$epwcApi.get(`/sites`);
    const site = sites.find(site => site.code.toUpperCase() === epwcSiteCode);
    if (site) site.daqSiteCode = daqSiteCode;
    commit('SET_SITE', site);
    commit('SET_LOADING', false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};