import Vue from 'vue';
import Vuex from 'vuex';
import pathify from 'vuex-pathify';

import sidebar from './modules/sidebar.module';
import daqLinks from './modules/daqLinks.module';
import solarmodules from './modules/solarmodules.module';
import daqs from './modules/daqs.module';
import sensors from './modules/sensors.module';
import site from './modules/site.module';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    sidebar,
    daqLinks,
    solarmodules,
    daqs,
    sensors,
    site
  },
  strict: debug,
  plugins: [pathify.plugin]
});