import { make } from 'vuex-pathify';

const state = {
  daqSensors: [],
  epwcSensors: []
};

const getters = {
  ...make.getters(state),
  getSensorByUuid: state => uuid => state.daqSensors.find(sensor => sensor.uuid === uuid) || state.epwcSensors.find(sensor => sensor.uuid === uuid)
};

const mutations = make.mutations(state);

const actions = {
  async setSensors({ commit, rootGetters }) {
    const site = rootGetters['site/site'];
    if (site) {
      const [daqSensors, epwcSensors] = await Promise.all([
        this.$epwcApi.get(`/sites/${site.daqSiteCode}/sensors`),
        this.$epwcApi.get(`/sites/${site.code}/sensors`)
      ]);

      commit('SET_DAQ_SENSORS', daqSensors);
      commit('SET_EPWC_SENSORS', epwcSensors);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};