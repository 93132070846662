import { make } from 'vuex-pathify';

const state = {
  solarModules: []
};

const getters = {
  solarModules: (state, _getters, _rootState, rootGetters) => {
    return state.solarModules.map((solarModule) => {
      const associations = {};

      const link = rootGetters['daqLinks/getLinkByModuleUuid'](solarModule.uuid);
      const linkedDaq = link && rootGetters['daqs/getDaqByUuid'](link.daq.uuid);
      if (linkedDaq) associations.linkedDaq = linkedDaq.name;
      return { ...solarModule, ...associations };
    });
  },
  getSolarModuleByUuid: (_state, _getters) => uuid => _getters.solarModules.find(solarModule => solarModule.uuid === uuid)
};

const mutations = make.mutations(state);

const actions = {
  async setSolarModules({ commit, rootGetters }) {
    const site = rootGetters['site/site'];
    if (site) {
      const solarModules = await this.$epwcApi.get(`/sites/${site.daqSiteCode}/modules`);
      commit('SET_SOLAR_MODULES', solarModules);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};