import Vue from 'vue';
import Router from 'vue-router';

const TheContainer = () => import('@/containers/TheContainer');
const Dashboard = () => import('@/views/Dashboard');
const Page404 = () => import('@/views/Page404');
const Page403 = () => import('@/views/Page403');

Vue.use(Router);

export default new Router({
  mode: 'hash',
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: TheContainer,
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard
        }
      ]
    },
    {
      path: '/forbidden',
      name: 'Page 403',
      component: Page403
    },
    {
      path: '*',
      name: 'Page 404',
      component: Page404
    }
  ];
}

