import Vue from 'vue';
import { VBTooltipPlugin, BVToastPlugin } from 'bootstrap-vue';

import { iconsSet as icons } from './assets/icons/icons.js';

import ApiPlugin from './plugins/api';
import StackdriverErrorsPlugin from './plugins/stackdriverErrors';

import App from './App.vue';
import store from './store';
import router from './router';

function initPlugins(kauth) {
  const stackdriverErrors = new StackdriverErrorsPlugin(kauth);
  stackdriverErrors.setup();

  const controlsApi = new ApiPlugin('controlsApi', 
    process.env.VUE_APP_CONTROLS_API_URL, async (opt) => {
      const token = await kauth.getValidToken();
      const authHeaders = { Authorization: `Bearer ${token}` };
      opt.headers = { ...authHeaders, ...opt.headers };
      return opt;
    });

  const epwcApi = new ApiPlugin('epwcApi',
    process.env.VUE_APP_EPWC_API_URL, async (opt) => {
      const token = await kauth.getValidToken();
      const authHeaders = { Authorization: `Bearer ${token}` };
      opt.headers = { ...authHeaders, ...opt.headers };
      return opt;
    });

  Vue.use(kauth);
  Vue.use(stackdriverErrors);
  Vue.use(controlsApi);
  Vue.use(epwcApi);
  Vue.use(VBTooltipPlugin);
  Vue.use(BVToastPlugin);
  store.$epwcApi = epwcApi;
}

async function initStoreData() {
  await store.set('site/site');
  store.set('solarmodules/solarModules');
  store.set('daqs/daqs');
  store.set('sensors/sensors');
  store.set('daqLinks/links');
}

export default function init(kauth) {
  Vue.config.performance = true;
  initPlugins(kauth);
  initStoreData();

  router.beforeEach(async (to, from, next) => {
    await kauth.getValidToken();
    const validUser = 
      kauth.hasSiteRole(process.env.VUE_APP_DAQ_SITE_CODE, 'monitor') 
      && kauth.hasSiteRole(process.env.VUE_APP_EPWC_SITE_CODE, 'monitor');
    
    if (!validUser && to.name !== 'Page 403') next({ name: 'Page 403' });
    else if (validUser && to.name === 'Page 403') next({ name: 'Dashboard' });
    else next();
  });

  /* eslint-disable no-new */
  new Vue({
    el: '#app',
    store,
    router,
    icons,
    render(h) {
      return h(App);
    }
  });
}