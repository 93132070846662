import { make } from 'vuex-pathify';

const state = {
  links: []
};

const getters = {
  ...make.getters(state),
  getLinkByDaqUuid: state => uuid => state.links.find(link => link.daq.uuid === uuid),
  getLinkByModuleUuid: state => uuid => state.links.find(link => link.module.uuid === uuid)
};

const mutations = make.mutations(state);

const actions = {
  async setLinks({ commit, rootGetters }) {
    const site = rootGetters['site/site'];
    if (site) {
      const links = await this.$epwcApi.get(`/sites/${site.daqSiteCode}/links`);
      commit('SET_LINKS', links);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};