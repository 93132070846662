<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">
  @import '~bootstrap/dist/css/bootstrap.css';
  @import '~bootstrap-vue/dist/bootstrap-vue.css';
  @import 'assets/scss/style';
  @import '~flatpickr/dist/flatpickr.css';
  @import '~vue-slider-component/lib/theme/default.scss';
</style>

<style>
body {
  height: 100%;
}

html {
  height: 100%;
}
</style>