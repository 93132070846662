import {
  cilLoopCircular,
  cilHome
} from '@coreui/icons';

export const iconsSet = Object.assign(
  {
    cilLoopCircular,
    cilHome
  }
);
