import { make } from 'vuex-pathify';

const state = {
  daqs: []
};

const getters = {
  ...make.getters(state),
  getDaqByUuid: state => uuid => state.daqs.find(daq => daq.uuid === uuid)
};

const mutations = make.mutations(state);

const actions = {
  async setDaqs({ commit, rootGetters }) {
    const site = rootGetters['site/site'];
    if (site) {
      const daqs = await this.$epwcApi.get(`/sites/${site.daqSiteCode}/daqs`);
      commit('SET_DAQS', daqs);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};